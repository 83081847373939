export const sectionTitle = "CORE TEAM";
export const teamMemberItems = [
  {
    description: [
      "As ex-head of marketing for Harmony, Garlam was also behind the marketing of some of the top DeFi and NFT projects in the space.",
      "As an ex-investment banker at JP Morgan & ex-Management consultant at Deloitte, Garlam exerts influence in various parts of the blockchain ecosystem through his in-depth connections with the communities in China, Korea, Canada and US",
    ],
    memberName: "GARLAM WON",
    memberPhoto: "/img/team/garlam-won.jpg"
  },
  {
    description: [
      "Jae is our superstar blockchain engineer. He became known in the space as one of the developers\n" +
      "                        behind the EOS mainnet launch. He runs HKEOS, a top block producer on EOS and WAX, and was\n" +
      "                        behind bloks.io — the #1 market share EOSIO block explorer.",
      "He is also an advisor for AlienWorlds and TomorrowBC. Jae specializes in security and smart\n" +
      "                        contract architecture, having won numerous bug bounties including Block.one's. Jae is often\n" +
      "                        invited to mentor at blockchain hackathons. In his spare time, he enjoys playing basketball.",
    ],
    memberPhoto: "/img/team/jae-chung.jpg",
    memberName: "JAE CHUNG"
  },
  {
    description: [
      "Nilotpal headed the Business at Marlin Labs, and was instrumental in early growth of the\n" +
      "                        company.",
      "He started his career as an Investment banker with Deutsche Bank where he worked on deal\n" +
      "                        structuring, valuation and IPO processes, and later moved on to Strategy consulting at Accenture\n" +
      "                        working with more than 15 clients on their business growth, risk and other strategic models.\n" +
      "                        Earlier he graduated from University of Delhi with a Masters degree in Strategy and Finance"
    ],
    memberPhoto: "/img/team/nilotpal-mukherjee.jpg",
    memberName: "NILOTPAL MUKHERJEE"
  }
];