import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Container} from "../Container/Container";
import {Row} from "../Row/Row";
import {EntryTitle} from "../../components/EntryTitle/EntryTitle";
import {Slider} from "../../components/Slider/Slider";
import {sectionTitle, piecesSliderItems} from "./constants";
import {PiecesSliderNavigation} from "../../components/PiecesSliderNavigation/PiecesSliderNavigation";

export const PiecesSections = () => {

  const startIndex = useMemo(() => {
    const hash = window.location.hash.substring(1);
    if (!hash) {
      return 0;
    }
    const needle = piecesSliderItems.findIndex(item => item.hash === hash);
    return needle > -1 ? needle : 0
  }, []);

  const [slider, setSlider] = useState(null);
  useEffect(() => {
    setSlider(window.jQuery(".pieces-sections .section-carousel").slick({
      arrows: true,
      appendArrows: ".pieces-sections .carousel-arrows",
      dots: true,
      initialSlide: startIndex,
      appendDots: ".pieces-sections .carousel-dots",
      fade: true,
      waitForAnimate: false,
    }));
  }, [startIndex]);

  const renderFunction = useCallback((item, index) => {
    return (
      <div key={`pieces-sections-${index}`} className="slide-item" data-hash={item.hash} data-title={item.title}>
        <div className="item-descr">
          <div className="item-title">
            <p>{item.title}</p>
          </div>
          <div className="item-content">
            {item.description.map((item, id) => (
              <p key={id}>{item}</p>
            ))}
          </div>
        </div>
        <div className="item-image"><img src={item.image} alt={item.title}/></div>
      </div>
    )
  }, []);

  const sliderControl = useMemo(() => {
    return (
      <div className="carousel-controls">
        <div className="carousel-arrows"/>
        <div className="carousel-dots"/>
      </div>
    )
  }, []);

  return (
    <section id="the-pieces" className="pieces-sections">
      <div className="bg-left col-3"/>
      <div className="bg-right col-9"/>
      <Container>
        <Row className="row">
          <div className="cols d-flex flex-row">
            <div className="column col-3">
              <EntryTitle title={sectionTitle} variation={'h2'}/>
              <PiecesSliderNavigation slides={piecesSliderItems} slider={slider} initialSlide={startIndex}/>
            </div>
            <div className="column col-9">
              <div className="section-title">
                <p><span>Intricately designed pieces that build the Genesis</span> <span>Shard ecosystem</span></p>
                
              </div>
              <Slider
                sliderClassName={"section-carousel"}
                sliderContainerSelector={".pieces-sections .section-carousel"}
                items={piecesSliderItems}
                renderFunction={renderFunction}
                control={sliderControl}
              />
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
};
