import React from 'react';
import {routes} from "./constants";

export const NavigationItems = ({mobile = false}) => {
  return (
    <ul>
      {
        routes.map((route, id) => (
            <li key={`menu-${id}`}><a href={route.href}>{route.label}</a></li>
          )
        )}
      {mobile && (
        <li key={`menu-${routes.length + 1}`}><a href="https://app.genshards.com" target="_blank">GO TO APP</a></li>
      )}
    </ul>
  );
};