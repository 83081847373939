import React, {useCallback} from 'react';
import ContactForm from "../ContactForm";

export const GetEarlyAccessButton = ({href}) => {

 /* const handleOpen = useCallback(() => {
    window.jQuery('#contact-modal').modal('show');
  }, []);*/

  return (
    <React.Fragment>
      <a href={'https://app.genshards.com'}
         target={"_blank"}
         className="btn btn-purple">Go to App</a>
{/*
      <ContactForm/>
*/}
    </React.Fragment>
  );
};