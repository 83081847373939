import React from 'react';
import {Container} from "../Container/Container";
import {Row} from "../Row/Row";
import ColumnBefore from "./components/ColumnBefore/ColumnBefore";
import {ColumnAfter} from "./components/ColumnAfter/ColumnAfter";

export const CompareTokensSection = () => {
  return (
    <section className="compare-tokens-sections">
      <Container>
        <Row className="row">
          <div className="cols d-flex flex-row">
            <ColumnBefore/>
            <ColumnAfter/>
          </div>
        </Row>
      </Container>
    </section>
);
};
