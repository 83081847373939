import React from 'react';
import {Container} from "../Container/Container";
import {Row} from "../Row/Row";
import {SectionInner} from "../../components/SectionInner/SectionInner";
import {EntryTitle} from "../../components/EntryTitle/EntryTitle";
import {sectionTitle} from "./constants";
import {SectionContent} from "../../components/SectionContent/SectionContent";

export const GenShardsSection = () => {
  return (
    <section id="gen-shards" className="gen-shards-section">
      <Container>
        <Row className="row">
          <SectionInner prefix={"gen-shards-section"}>
            <EntryTitle title={sectionTitle} variation={'h2'}/>
            <SectionContent>
              <img src="img/gen_nftx.svg" alt="GEN SHARDS ECOSYSTEM"/>
            </SectionContent>
          </SectionInner>
        </Row>
      </Container>
    </section>
  );
};
