export const sectionTitle = "ARCHITECTURE";
export const serviceItems = [
  {
    hash: "pre-ido",
    title: "PRE–IDO TOKENS",
    description: ["ap in the market. 0 liquidity and 0 market while the project figures out the IDO plan."],
    image: "/img/services/pre-ido.svg"
  },
  {
    hash: "nft-wrap",
    title: "NFT WRAPPED TOKENS",
    description: [
      "Tokens are wrapped in NFT to",
      "• Retain full control of the tokens and supply in the NFT",
      "• Create isolated ecosystem of NFT wrapped tokens that doesn’t interfere with IDO plans",
      "• Enable liquidity of illiquid tokens",
    ],
    image: "/img/services/gen-ecosystem.svg"

  },
  {
    hash: "gen-ecosystem",
    title: "GEN ECOSYSTEM",
    description: [
      "Tokens can be swapped within the GENESIS SHARD Ecosystem. Leveraging the existing network and influence to harness the marketing power of the community",
    ],
    image: "/img/services/gen-tokens.svg"

  },
  {
    hash: "gen-nfx",
    title: "GEN NFTX",
    description: ["Crosschain marketplace for DeFi products coming out of GENESIS SHARDS ecosystem with the ability to shard NFTs for fractional swaps. Powered by Polkadot."],
    image: "/img/services/gen-nftx.svg"
  },
  {
    hash: "post-ido",
    title: "POST IDO OPTIONS",
    description: ["Marketplace for DeFi products coming out of GENESIS SHARDS ecosystem with the ability to shard NFTs for fractional swaps"],
    image: "/img/services/ido-option.svg"
  }
];