export const beforeColTitle = "BEFORE";
export const afterColTitle = "AFTER";

export const marketList = [
  {
    title: "NEW TOKENS",
    img:"/img/new-tokens.svg",
    description: "Illiquid tokens due to various reasons including interfering with IDO plan"
  },
  {
    title: "NFT WRAPPERS",
    img:"/img/nft-wrap.svg",
    description: "NFT wrapped liquid tokens that isolates circulating tokens. Enabling early price discovery and launchpad style crowd-sourced exposure"
  },
  {
    title: "IDO",
    img:"/img/IDO.svg",
    description: "NFTs containing Pre-IDO tokens to be swapped for Post-IDO tokens"
  }
]