import React from 'react';
import EntryTitle from "../../../../components/EntryTitle";
import {partnerListTitle, partnerSliderTitle, partnersListItems} from "../../constants";
import PartnersList from "../../../../components/PartnersList";

export const PartnersListColumn = () => {
  return (
    <div className="column partners col-6">
      <EntryTitle title={partnerSliderTitle} variation={'h2'} classes={"hide-on-desktop"}/>
      <div className={`entry-title`}>
        <h3><span>FOUNDATION FOR</span><span>GLOBAL ECOSYSTEM</span></h3>
      </div>
      <div className={"investor-logo"}>
        <img src={"/img/partners/m6.svg"} alt={"m6"}/>
      </div>
    </div>
  );
};
