import React from 'react';
import Container from "../Container";
import Row from "../Row";
import SectionInner from "../../components/SectionInner";
import EntryTitle from "../../components/EntryTitle";
import {sectionTitle} from "./constants";
import SectionContent from "../../components/SectionContent";


export const RethinkingNftsSection = () => {
  return (
    <section id="rethinking-nfts" className="rethinking-ntfs-section">
      <Container>
        <Row>
          <SectionInner prefix={"rethinking-ntfs-section"}>
            <div className={"inner-wrap col-7"}>
              <EntryTitle title={sectionTitle} variation={'h2'}/>
              <SectionContent classes={"col-5"}>
                <p>Not only as collectibles but as DeFi options</p>
                <p>By wrapping timelocked fungible tokens into NFTs, we can create a new market for previously illiquid tokens.</p>
              </SectionContent>
             </div>
          </SectionInner>
        </Row>
      </Container>
    </section>
  );
};
