import React, {useCallback} from 'react';

export const SignInButton = () => {
  const handleClick = useCallback((event) => {
    event.preventDefault();
    window.jQuery('.mobile-nav').slideToggle('fast');
  }, []);
  return (
    <div className="right-navigation">
      <a href="#" className="btn btn-grey mobile-menu" onClick={handleClick}>
        <i className="fas fa-ellipsis-h"/></a>
      <a href="https://app.genshards.com" target="_blank" className="btn btn-grey sign-in">Go to App</a>
    </div>
  );
};
