import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Container from "../Container";
import Row from "../Row";
import SectionInner from "../../components/SectionInner";
import EntryTitle from "../../components/EntryTitle";
import {sectionTitle, serviceItems} from "./constants";
import Slider from "../../components/Slider";
import ServicesSliderNavigation from "../../components/ServicesSliderNavigation";

export const ServiceSection = () => {

  const startIndex = useMemo(() => {
    const hash = window.location.hash.substring(1);
    if (!hash) {
      return 0;
    }

    const needle = serviceItems.findIndex(item => item.hash === hash);
    return needle > -1 ? needle : 0
  }, []);

  const [slider, setSlider] = useState(null);

  useEffect(() => {
    setSlider(window.jQuery(".services-section .services-list").slick(
      {
        arrows: true,
        appendArrows: ".services-section .carousel-arrows",
        dots: true,
        appendDots: ".services-section .carousel-dots",
        initialSlide: startIndex,
        waitForAnimate: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              adaptiveHeight: true
            }
          },
        ]
      }));
  }, [startIndex]);

  const renderFunction = useCallback((item, id) => {
    return (
      <div key={`services-section-${id}`} className="service-item" data-hash={item.hash} data-title={item.title}>
        <div className="cols d-flex">
          <div className="column">
            <div className="descr-wrap">
              <div className="item-title"><p>{item.title}</p></div>
              <div className="description">
                {item.description.map((item, id) => (
                  <p key={id}>{item}</p>
                ))}
              </div>
            </div>
          </div>
          <div className="column">
            <div className="service-image">
              <img src={item.image} alt={item.title}/>
            </div>
          </div>
        </div>
      </div>
    )
  }, []);

  const controlSection = useMemo(() => {
    return (
      <div className="carousel-controls_wrap">
        <div className="carousel-controls">
          <div className="carousel-dots"/>
          <div className="carousel-arrows"/>
        </div>
        <ServicesSliderNavigation slides={serviceItems} slider={slider} initialSlide={startIndex}/>
      </div>
    )
  }, [slider, startIndex]);

  return (
    <section id="architecture" className="services-section">
      <Container>
        <Row>
          <SectionInner prefix={"services-section"}>
            <EntryTitle title={sectionTitle} variation={'h2'}/>
            <Slider
              sliderClassName={"services-list"}
              control={controlSection}
              renderFunction={renderFunction}
              items={serviceItems}
            />
          </SectionInner>
        </Row>
      </Container>
    </section>
  );
};
