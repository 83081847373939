import React from 'react';
import EntryTitle from "../../../../components/EntryTitle";
import {afterColTitle, marketList} from "../../constants";
import SectionContent from "../../../../components/SectionContent";

export const ColumnAfter = () => {
  return (
    <div className="column-after col-8">
      <EntryTitle title={afterColTitle} variation={'h3'}/>
      <SectionContent>
        <div className="image-block">
          <img src="/img/after.png" alt="After"/>
          <p className="title">New Market</p>
        </div>
        <ul>
          {marketList.map((item, id) => (
            <li key={id}>
              <div className="icon">
                <img src={item.img} alt={item.title}/>
              </div>
              <div className="item-content">
                <p className="title">{item.title}</p>
                <p className="descr">{item.description}</p>
              </div>
            </li>
          ))}
        </ul>
      </SectionContent>
    </div>
  );
};
