import React from 'react';
import {footerMenu} from "./constants";

export const FooterWidgetMenu = () => {
  return (
    <div className="footer-widget-1 col-3">
      <ul>
        {footerMenu.map((menu, id) => {
          return <li key={id}><a href={menu.href}>{menu.title}</a></li>
        })}
      </ul>
    </div>
  );
};
