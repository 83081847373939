import React from 'react';
import Container from "../Container";
import Row from "../Row";
import Logo from "../../components/Logo";
import FooterWidgetMenu from "../../components/FooterWidgetMenu";
import FooterWidgetInformation from "../../components/FooterWidgetInformation";

export const Footer = () => {
  return (
    <footer className="site-footer">
      <Container>
        <Row>
          <div className="d-flex flex-row justify-content-between">
            <Logo src={"/img/footer-logotype.svg"} alt={"Genesis"} classes={"col-3"}/>
            <FooterWidgetMenu/>
            <FooterWidgetInformation/>
          </div>
        </Row>
      </Container>
    </footer>
  );
};
