import React, {useCallback, useEffect, useMemo} from "react";
import EntryTitle from "../../../../components/EntryTitle";
import {investors, partnerSliderItems, partnerSliderTitle} from "../../constants";
import Slider from "../../../../components/Slider";

export const PartnerSliderColumn = () => {
  // useEffect(() => {
  //   window.jQuery(".partners-slider").slick({
  //     arrows: true,
  //     appendArrows: ".investor-partners-section .carousel-arrows",
  //     dots: true,
  //     appendDots: ".investor-partners-section .carousel-dots",
  //     fade: true,
  //     waitForAnimate: false,
  //     responsive: [
  //       {
  //         breakpoint: 768,
  //         settings: {
  //           adaptiveHeight: true
  //         }
  //       },
  //     ]
  //   })
  // }, []);

  // const renderFunction = useCallback((item, id) => {
  //   return (
  //     <div className="partner-item" key={id}>
  //       <div className="descr">
  //         <p>{item.description}</p>
  //       </div>
  //       <div className="author">
  //         <p>{item.author}</p>
  //         <p className="company-name">{item.companyName}</p>
  //       </div>
  //     </div>
  //   )
  // }, []);
  //
  // const control = useMemo(() => {
  //   return (
  //     <div className="carousel-controls">
  //       <div className="carousel-dots"/>
  //       <div className="carousel-arrows"/>
  //     </div>
  //   )
  // }, []);
  return (
    <div className="column partners-slide col-6">
      <EntryTitle title={partnerSliderTitle} variation={"h2"} />
      
      {/* list investors logo */}
      <div className="investors">
        {
          investors.map(investor => (
            <img src={investor.src} alt={investor.alt} />
          ))
        }
      </div>
      {/*<Slider*/}
      {/*  sliderClassName={"partners-slider"}*/}
      {/*  items={partnerSliderItems}*/}
      {/*  control={control}*/}
      {/*  renderFunction={renderFunction}*/}
      {/*/>*/}
    </div>
  );
};
