import React from 'react';
import Container from "../Container";
import Row from "../Row";
import PartnersListColumn from "./components/PartnersListColumn";
import PartnerSliderColumn from "./components/PartnerSliderColumn";

export const InvestorPartnerSection = () => {
  return (
    <section id="investors-partners" className="investor-partners-section">
      <div className="bg-left col-6"/>
      <div className="bg-right col-6"/>
      <Container className="container">
        <Row>
          <div className="cols d-flex">
            <PartnersListColumn/>
            <PartnerSliderColumn/>
          </div>
        </Row>
      </Container>
    </section>
  );
};
