import React from 'react';
import EntryTitle from "../../../../components/EntryTitle";
import {beforeColTitle} from "../../constants";
import SectionContent from "../../../../components/SectionContent";

export const ColumnBefore = () => {
  return (
    <div className="column-before col-4">
      <div className="bg-line-left"/>
      <EntryTitle title={beforeColTitle} variation={'h3'}/>
      <SectionContent>
        <img src="/img/before.png" alt="Before"/>
        <p className="title">Tokens = Illiquid</p>
        <ul>
          <li>
            <p>PRE-IDO</p>
          </li>
          <li>
            <p>IDO</p>
          </li>
        </ul>
      </SectionContent>
    </div>
  );
};

export default ColumnBefore;