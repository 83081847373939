import React, {useCallback, useEffect, useState} from 'react';

export const PiecesSliderNavigation = ({slides, slider, initialSlide}) => {

  const [active, setActive] = useState(initialSlide);

  const handleClickOnSlider = useCallback((event, slick, currentSlide, nextSlide) => {
    setActive(state => state === nextSlide ? state : nextSlide)
  }, [setActive]);

  useEffect(() => {
    if (slider) {
       window.jQuery('.pieces-sections .section-carousel').on('beforeChange', handleClickOnSlider)
     }
    return () => {
      if (slider) {
        window.jQuery('.pieces-sections .section-carousel').off('beforeChange', handleClickOnSlider)
      }
    }
  }, [slider,handleClickOnSlider]);


  const handleClick = useCallback((e, index) => {
    e.preventDefault();
    slider.slick('slickGoTo', index)
    setActive(index)
  }, [slider]);

  return (
    <div className={"slider-navigation"}>
      <nav id="slider-nav" className="slider-nav">
        <ul>
          {slides.map((slide, index) => (
            <li key={index}>
              <a
                className={active === index ? "active" : ""}
                href={`#${slide.hash}`}
                onClick={(e) => handleClick(e, index)}>
                {slide.titleParts.map((part,id) => (<span key={id}>{part}</span>))}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};