import React, {useMemo} from 'react';

export const Slider = ({sliderClassName, items, renderFunction, control = null}) => {

  const sliderItems = useMemo(() => {
    return items.map((item, id) => renderFunction(item, id))
  }, [items, renderFunction]);

  return (
    <React.Fragment>
      <div className={sliderClassName}>
        {sliderItems}
      </div>
      {control}
    </React.Fragment>
  );
};