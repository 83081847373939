import React from 'react';
import {aboutLinks, aboutTitle, disclaimerTitle} from "./constants";

export const FooterWidgetInformation = () => {
  return (
    <div className="footer-widget-2 col-6">
      <div className="widget-row">
        <p className="title">
          <a className="link--navigation" href="#root">{aboutTitle}</a>
        </p>
        <div className="cols d-flex flex-row">
          {aboutLinks.map(({title, action, label}, id) => {
            return <p key={id}>{title} <a className="link" href={action}>{label}</a></p>
          })}
        </div>
      </div>
      <div className="widget-row">
        <p className="title">{disclaimerTitle}</p>
        <p className="disclaimer">Nothing herein constitutes an offer to sell, or the solicitation of an offer to buy, any securities or tokens.</p>
      </div>
    </div>
  );
};