import React, {useCallback, useEffect, useMemo} from 'react';
import {Container} from "../Container/Container";
import SectionInner from "../../components/SectionInner";
import EntryTitle from "../../components/EntryTitle";
import {sectionTitle, teamMemberItems} from "./constants";
import Slider from "../../components/Slider";
import Row from "../Row";

export const TeamSection = () => {

  useEffect(() => {
    window.jQuery(".team-section .team-members").slick(
      {
        arrows: true,
        appendArrows: ".team-section .carousel-arrows",
        dots: true,
        appendDots: ".team-section .carousel-dots",
        fade: true,
        waitForAnimate: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              adaptiveHeight: true
            }
          },
        ]
      })
  }, []);

  const sliderControl = useMemo(() => {
    return (
      <div className="carousel-controls_wrap">
        <div className="carousel-controls">
          <div className="carousel-dots"/>
          <div className="carousel-arrows"/>
        </div>
      </div>
    )
  }, []);

  const renderFunction = useCallback((item, id) => {
    return <div key={id} className="team-member">
      <div className="cols d-flex">
        <div className="column col-6">
          <div className="descr">
            {item.description.map((item, id) => (
              <p key={id}>{item}</p>
            ))}
          </div>
        </div>
        <div className="column col-6">
          <div className="member-photo">
            <img src={item.memberPhoto} alt={item.memberName}/>
          </div>
        </div>
      </div>
      <div className="member-name">
        <h3>{item.memberName}</h3>
      </div>
    </div>
  }, []);

  return (
    <section id="core-team" className="team-section">
      <Container>
        <Row>
          <SectionInner prefix={"team-section"}>
            <EntryTitle variation={'h2'} title={sectionTitle}/>
            {sliderControl}
            <Slider renderFunction={renderFunction} sliderClassName={"team-members"} items={teamMemberItems}/>
          </SectionInner>
        </Row>
      </Container>
    </section>
  );
};
