
export const partnerSliderTitle = 'INVESTORS PARTNERS';
export const partnerSliderItems = [
  {
    description: "\"Currently, institutional investors interested in price-efficient, direct exposure to digital\n" +
      "                      assets either need to absorb high fees or go through the hassle of managing multiple exchange\n" +
      "                      accounts. Tagomi offers these investors and others with indirect exposure to digital assets a\n" +
      "                      streamlined solution that can dramatically reduce fees, back-office headcount and price\n" +
      "                      slippage.\"",
    author: "Napoleon, Partner at",
    companyName: "Founders Fund"
  },
  {
    description: "\"Currently, institutional investors interested in price-efficient, direct exposure to digital\n" +
      "                      assets either need to absorb high fees or go through the hassle of managing multiple exchange\n" +
      "                      accounts. Tagomi offers these investors and others with indirect exposure to digital assets a\n" +
      "                      streamlined solution that can dramatically reduce fees, back-office headcount and price\n" +
      "                      slippage.\"",
    author: "Napoleon, Partner at",
    companyName: "Founders Fund"
  },
  {
    description: "\"Currently, institutional investors interested in price-efficient, direct exposure to digital\n" +
      "                      assets either need to absorb high fees or go through the hassle of managing multiple exchange\n" +
      "                      accounts. Tagomi offers these investors and others with indirect exposure to digital assets a\n" +
      "                      streamlined solution that can dramatically reduce fees, back-office headcount and price\n" +
      "                      slippage.\"",
    author: "Napoleon, Partner at",
    companyName: "Founders Fund"
  },
  {
    description: "\"Currently, institutional investors interested in price-efficient, direct exposure to digital\n" +
      "                      assets either need to absorb high fees or go through the hassle of managing multiple exchange\n" +
      "                      accounts. Tagomi offers these investors and others with indirect exposure to digital assets a\n" +
      "                      streamlined solution that can dramatically reduce fees, back-office headcount and price\n" +
      "                      slippage.\"",
    author: "Napoleon, Partner at",
    companyName: "Founders Fund"
  },
  {
    description: "\"Currently, institutional investors interested in price-efficient, direct exposure to digital\n" +
      "                      assets either need to absorb high fees or go through the hassle of managing multiple exchange\n" +
      "                      accounts. Tagomi offers these investors and others with indirect exposure to digital assets a\n" +
      "                      streamlined solution that can dramatically reduce fees, back-office headcount and price\n" +
      "                      slippage.\"",
    author: "Napoleon, Partner at",
    companyName: "Founders Fund"
  }
];
export const partnersListItems = [
  {src: "/img/partners/multicoin-capital.png", alt: "Multicoin Capital"},
  {src: "/img/partners/paradigm.png", alt: "Paradigm"},
  {src: "/img/partners/crescent-crypto.png", alt: "Crescent crypto"},
  {src: "/img/partners/bitwise.png", alt: "Bitwise"},
  {src: "/img/partners/pantera.png", alt: "Pantera"},
  {src: "/img/partners/galaxy.png", alt: "Galaxy"},
  {src: "/img/partners/founders-fund.png", alt: "Founders fund"}
];

export const investors = [
  {
    src: "/img/investors/moonwhale.svg",
    alt: "moonwhale",
  },
  {
    src: "/img/investors/shima-capital.svg",
    alt: "shima-capital",
  },
  {
    src: "/img/investors/astronaut-capital.svg",
    alt: "astronaut-capital",
  },
  {
    src: "/img/investors/twin-apex-capital.svg",
    alt: "twin-apex-capital",
  },
  {
    src: "/img/investors/linkopad.svg",
    alt: "linkopad",
  },
  {
    src: "/img/investors/morningstar.svg",
    alt: "morningstar",
  },
  {
    src: "/img/investors/black-edge-capital.svg",
    alt: "black-edge-capital",
  },
  {
    src: "/img/investors/pnyx.svg",
    alt: "pnyx",
  },
  {
    src: "/img/investors/master-ventures.svg",
    alt: "master-ventures",
  },
  {
    src: "/img/investors/chain-capital.svg",
    alt: "chain-capital",
  },
  {
    src: "/img/investors/blocksync-ventures.svg",
    alt: "blocksync-ventures",
  },
  {
    src: "/img/investors/block-dream-fund.svg",
    alt: "block-dream-fund",
  },
  {
    src: "/img/investors/solidity-ventures.svg",
    alt: "solidity-ventures",
  },
  {
    src: "/img/investors/3commas.svg",
    alt: "3commas",
  },
  {
    src: "/img/investors/ld.svg",
    alt: "ld",
  },
  {
    src: "/img/investors/spark-digital-capital.svg",
    alt: "spark-digital-capital",
  },
  {
    src: "/img/investors/moon-rock-capital.svg",
    alt: "moon-rock-capital",
  },
  {
    src: "/img/investors/petrock-capital.svg",
    alt: "petrock-capital",
  },
  {
    src: "/img/investors/m6.svg",
    alt: "m6",
  },
  {
    src: "/img/investors/inclusion-capital.svg",
    alt: "inclusion-capital",
  },
  {
    src: "/img/investors/follow-the-seed.svg",
    alt: "follow-the-seed",
  },
  {
    src: "/img/investors/vendetta.png",
    alt: "vendetta-capital",
  },
  {
    src: "/img/investors/au21-capital.svg",
    alt: "au21-capital",
  },
  {
    src: "/img/investors/x21.svg",
    alt: "x21",
  },
];
