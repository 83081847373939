import React from 'react';
import Container from "../Container";
import SectionInner from "../../components/SectionInner";
import Row from "../Row";
import SectionContent from "../../components/SectionContent";
import {EntryTitle} from "../../components/EntryTitle/EntryTitle";
import {sectionTitle} from "./constants";

export const ContactSection = () => {
  return (
    <section id="contact" className="contact-section">
      <Container>
        <Row>
          <SectionInner prefix={"contact-section"}>
            <EntryTitle title={sectionTitle} variation={'h2'}/>
            <SectionContent>
              <p>Get connected</p>
              <ul>
                <li><a href="https://t.me/genshardsANN" target={"_blank"}><i className="fab fa-telegram-plane"/></a></li>
                <li><a href="https://twitter.com/GenShards" target={"_blank"}><i className="fab fa-twitter"/></a></li>
              </ul>
              <p className="lets-chat">Lets chat on Telegram <a href="https://t.me/genshardsCHAT" target={"_blank"}>@genshardsCHAT</a></p>
            </SectionContent>
          </SectionInner>
        </Row>
      </Container>
    </section>
  );
};
