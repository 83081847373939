import React from "react";
import Header from "./common/Header";
import MainSection from "./common/MainSection";
import IntroduceSection from "./common/IntroduceSection";
import RethinkingNftsSection from "./common/RethinkingNftsSection";
import CompareTokensSection from "./common/CompateTokensSection";
import PiecesSections from "./common/PiecesSections";
import GenShardsSection from "./common/GenShardsSection";
import ServiceSection from "./common/ServiceSection";
import InvestorPartnerSection from "./common/InvestorPartnerSection";
import TeamSection from "./common/TeamSection";
import ContactSection from "./common/ContactSection";
import Footer from "./common/Footer";

function App() {
  return (
    <React.Fragment>
      <Header/>
      <MainSection>
        <IntroduceSection/>
        <RethinkingNftsSection/>
        <CompareTokensSection/>
        <PiecesSections/>
        <GenShardsSection/>
        <ServiceSection/>
        <InvestorPartnerSection/>
        <TeamSection/>
        <ContactSection/>
      </MainSection>
      <Footer/>
    </React.Fragment>
  );
}

export default App;
