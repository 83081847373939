export const sectionTitle = "THE PIECES";
export const piecesSliderItems = [
  {
    hash: "sgs",
    title: "$GS TOKEN",
    titleParts: ["$GS", "TOKEN"],
    description: ["Utility token in the ecosystem. Swapped for elements such as GEN ACCESS."],
    image: "/img/the-pieces/sgs-token.svg"
  },
  {
    hash: "gen-access",
    title: "GEN ACCESS",
    titleParts: ["GEN", "ACCESS"],
    description: ["Entry into the GENESIS SHARDS Ecosystem allowing holders to acquire GEN TICKETS"],
    image: "/img/the-pieces/gen-access.svg"
  },
  {
    hash: "gen-tickets",
    title: "GEN TICKETS",
    titleParts: ["GEN", "TICKETS"],
    description: [
      "Each ticket will have varying amount of Pre-IDO tokens that are inside.",
      "Tickets can be traded on GEN NFTX or be swapped for Post-IDO tokens."
    ],
    image: "/img/the-pieces/gen-tickets.svg"
  },
  {
    hash: "gen-nftx",
    title: "GEN NFTX",
    titleParts: ["GEN", "NFTX"],
    description: ["Marketplace for DeFi products coming out of GENESIS SHARDS ecosystem with the ability to shard NFTs for fractional swaps"],
    image: "/img/the-pieces/gen-nftx.svg"
  }
];